import images from '../assets/images';

// Data.jsx
const CardData = {
    project1: {
        title: { zh: '踏入未來的市場掌控', en: 'Stepping into Future Market Control' },
        description: {
            zh: '在這個科幻風格的交易平台中，輸入你的交易所 API，即可開啟智能交易的未來。透過我們提供的先進交易策略，輕鬆掌握市場動態，實現高效自動化交易。無論你是新手還是資深交易者，我們的工具都能幫助你在這個高速變化的市場中作出更明智的決策。',
            en: 'In this sci-fi styled trading platform, simply input your exchange API to start smart trading. Our advanced trading strategies help you easily grasp market trends, achieving efficient automation. Whether you’re a beginner or a seasoned trader, our tools assist you in making smarter decisions in the fast-changing market.'
        },
        cardInfo: [
            { heading: { zh: '製作目的', en: 'Purpose' }, content: { zh: '旨在提升前端技術，探索科幻風格的 UI 設計', en: 'To enhance frontend skills and explore sci-fi UI design.' }},
            { heading: { zh: '使用工具', en: 'Tools Used' }, content: { zh: 'Figma/Html/Scss/React.js', en: 'Figma/Html/Scss/React.js' }},
            { heading: { zh: '製作時間', en: 'Duration' }, content: { zh: '1週設計, 1週開發', en: '1 week design, 1 week development' }}
        ],
        imagesList: [
            { image: images.trondex1, content: { 
                heading: { zh: '主要問題與解決思路', en: 'Key Issues & Solutions' },
                texts: {
                    zh: [
                        '背景製作需要兼顧科幻風格和簡潔性，確保不會干擾其他視覺元素。在這方面，我們使用漸變和簡潔的幾何元素作為背景，在這麼複雜的背景之下，只能根據不同的螢幕寬度來設計背景元件。',
                        '在 RWD（響應式設計）中處理不同背景的挑戰。由於需要在不同設備上保持一致的視覺效果，背景設計需要花費大量時間調整。為了有效解決這個問題，對於圖形的四個邊角分別拆四張圖來作為背景，以CSS能達到的線條來做設定，確保在各種裝置上呈現一致的背景效果。'
                    ],
                    en: [
                        'The background design needs to balance a sci-fi theme with simplicity to ensure it doesn’t overshadow other visual elements. We used gradients and simple geometric elements as the background, adapting components based on screen width.',
                        'Handling different backgrounds in responsive design was challenging. Ensuring visual consistency across devices took extensive adjustment. We solved this by splitting each graphic corner into separate images and applying CSS lines to achieve consistent backgrounds on all devices.'
                    ]
                }
            }},
            { image: images.trondex2 },
            { image: images.trondex3 },
            { image: images.trondex4 },
        ]
    },
    project2: {
        title: { zh: '科技公司形象網站', en: 'Technology Corporate Website' },
        description: {
            zh: '形象網站的設計旨在呈現公司在創新與技術領域的領先地位。整體設計採用現代而簡潔的風格，搭配冷色系的配色，如深藍、灰色和白色，以傳達科技的專業感與未來感。',
            en: 'The corporate website design highlights the company’s leadership in innovation and technology. It adopts a modern, minimalist style with a cool color palette—deep blue, gray, and white—to convey professionalism and a futuristic vision.'
        },
        cardInfo: [
            { heading: { zh: '製作目的', en: 'Purpose' }, content: { zh: '提升公司在科技領域的品牌形象，展現創新實力與技術專業性', en: 'To enhance the company’s brand image in technology, showcasing innovation and technical expertise.' }},
            { heading: { zh: '使用工具', en: 'Tools Used' }, content: { zh: 'Figma/Html/Scss/React.js', en: 'Figma/Html/Scss/React.js' }},
            { heading: { zh: '製作時間', en: 'Duration' }, content: { zh: '1週設計, 1週開發', en: '1 week design, 1 week development' }},
        ],
        imagesList: [
            { image: images.fs1, content: { 
                heading: { zh: '主要問題與解決思路', en: 'Key Issues & Solutions' },
                texts: {
                    zh: [
                        '在設計 UI 的過程中，我們主要面臨兩個挑戰：其一是如何確保設計風格在不同平台和設備上保持一致且具備吸引力，其二是如何創造出能夠提升用戶體驗的互動設計。',
                        '為了解決這些問題，我們專注於現代 UI/UX 設計，結合響應式設計技術，確保網站在各種設備上都能提供流暢和一致的視覺體驗。我們還注重使用微互動（micro-interactions）來增強用戶參與度，使整體設計更具親和力和未來感。'
                    ],
                    en: [
                        'We faced two main challenges in UI design: maintaining consistent style across platforms and devices, and creating an interactive design that enhances user experience.',
                        'To solve these, we focused on modern UI/UX design and responsive techniques for a smooth visual experience across devices. We also emphasized micro-interactions to boost user engagement, giving the design a friendly and futuristic appeal.'
                    ]
                }
            }},
            { image: images.fs2 },
            { image: images.fs3 },
        ]
    },
    project3: {
        title: { zh: 'NFT 兌換平台', en: 'NFT Exchange Platform' },
        description: {
            zh: '用戶可以在此平台上輕鬆交易、兌換各類 NFT 資產，無論是數字藝術品還是收藏品。我們提供安全且直觀的介面，讓每一位用戶都能便捷地體驗區塊鏈技術的魅力，並透過去中心化技術確保每筆交易的透明和可靠性。',
            en: 'This platform enables users to trade and exchange various NFTs, from digital art to collectibles. We provide a secure and intuitive interface, allowing every user to experience the allure of blockchain technology. Decentralized technology ensures transparency and reliability for every transaction.'
        },
        cardInfo: [
            { heading: { zh: '製作目的', en: 'Purpose' }, content: { zh: '根據客戶要求訂製網站，主要呈現永續傳承的感覺', en: 'To build a website tailored to client requirements, emphasizing a sense of sustainability and legacy.' }},
            { heading: { zh: '使用工具', en: 'Tools Used' }, content: { zh: 'Figma/illustrator', en: 'Figma/Illustrator' }},
            { heading: { zh: '製作時間', en: 'Duration' }, content: { zh: '1週設計', en: '1 week design' }},
        ],
        imagesList: [
            { image: images.eternal1, content: { 
                heading: { zh: '主要問題與解決思路', en: 'Key Issues & Solutions' },
                texts: {
                    zh: [
                        '這個 NFT 兌換平台的設計主要面臨以下挑戰：如何向用戶傳達“永續傳承”這一抽象概念，同時確保平台的使用流程簡單易懂且具有吸引力。',
                        '除了強調“永續傳承”這一理念之外，優化了用戶體驗，確保 NFT 兌換和購買流程直觀且快速，讓每一位訪客都能輕鬆了解並參與到平台的生態系統中。'
                    ],
                    en: [
                        'This NFT exchange platform faced challenges of conveying the abstract idea of “sustainability and legacy” while ensuring an intuitive and engaging user experience.',
                        'Beyond emphasizing “sustainability,” we optimized the user experience to ensure an intuitive and fast exchange and purchase process, allowing every visitor to easily engage with the platform ecosystem.'
                    ]
                }
            }},
            { image: images.eternal2 },
            { image: images.eternal3 },
        ]
    },
    project4: {
        title: { zh: '運動醫療健康監測', en: 'Sports Health Monitoring' },
        description: {
            zh: '這是一款專為運動愛好者和健康管理設計的 APP，能實時監測你的運動狀況與健康數據，並通過科學的數據分析提供個性化建議，幫助你更好地了解自己的身體狀況，提升運動效果與生活品質。',
            en: 'This app is designed for sports enthusiasts and health management, providing real-time monitoring of your activity and health data. It offers personalized insights based on scientific data analysis to help you better understand your body, improve exercise results, and enhance life quality.'
        },
        cardInfo: [
            { heading: { zh: '製作目的', en: 'Purpose' }, content: { zh: '幫助用戶有效監測運動與健康數據，促進更健康的生活方式', en: 'To help users effectively monitor activity and health data, promoting a healthier lifestyle.' }},
            { heading: { zh: '使用工具', en: 'Tools Used' }, content: { zh: 'Figma/illustrator', en: 'Figma/Illustrator' }},
            { heading: { zh: '製作時間', en: 'Duration' }, content: { zh: '2週設計', en: '2 weeks design' }},
        ],
        imagesList: [
            { image: images.medical1, content: { 
                heading: { zh: '主要問題與解決思路', en: 'Key Issues & Solutions' },
                texts: {
                    zh: [
                        '在開發運動醫療健康監測 APP 的過程中，面臨的主要挑戰是如何幫助用戶有效了解自己的健康狀況，並提供個性化的健康建議。首先，健康數據的收集和呈現需要簡單明瞭，以便用戶輕鬆理解自己的身體狀況；其次，數據的分析需要準確且符合個人特性，以提升用戶的信任度與參與感。',
                        '為了解決這些問題，我們採用現代化的健康監測技術，並設計了友好的數據可視化界面，讓用戶可以一目了然地了解自己的運動、壓力等各項健康指標。我們還加入了專業建議模組，根據用戶的實時數據提供個性化的建議，幫助他們更好地管理健康。'
                    ],
                    en: [
                        'During development of the sports health monitoring app, challenges included helping users effectively understand their health status and providing personalized health advice. Data collection and presentation needed simplicity, and data analysis had to be precise to increase user trust and engagement.',
                        'We used modern health monitoring technology and designed a user-friendly data visualization interface for clear insights into various health indicators like activity and stress. We also integrated a professional advice module to provide personalized recommendations based on real-time data, supporting better health management.'
                    ]
                }
            }},
            { image: images.medical2, content: {
                heading: { zh: '活動與壓力的實時監測', en: 'Real-time Activity & Stress Monitoring' },
                texts: {
                    zh: [
                        '活動監測部分提供用戶的睡眠分析，顯示進入睡眠時間、總睡眠時長、深度與淺度睡眠的分佈，並用簡單的圖表視覺化呈現整個晚上的睡眠狀況。通過這些數據，使用者可以了解自己的睡眠質量和模式，並根據詳細的數據進行自我調整以改善健康。',
                        '壓力監測部分則通過全天的壓力水平曲線圖，讓用戶清楚了解一天內壓力的波動情況，包括最高與最低壓力的時間點以及平均壓力水平。這有助於用戶更好地管理日常壓力，辨識高壓時段並採取相應措施來進行放鬆。'
                    ],
                    en: [
                        'The activity monitoring section provides sleep analysis, showing sleep onset, total duration, and distribution of deep and light sleep, visualized with simple charts to reflect overnight sleep patterns. This data allows users to understand sleep quality and make adjustments to improve health.',
                        'Stress monitoring features a daily stress level curve, showing fluctuations, including peak and low stress points and the average level. This aids users in managing daily stress by identifying high-stress periods and taking actions to relax.'
                    ]
                }
            }},
            { image: images.medical3, content: {
                heading: { zh: '健康指數、活動得分與健康中心', en: 'Health Index, Activity Score & Wellness Hub' },
                texts: {
                    zh: [
                        '這些頁面包括健康指數總覽、活動得分及加入健康中心的選項。健康指數頁面匯總了壓力、活動、睡眠等多方面的健康數據，活動得分頁面展示了用戶的日常活動狀況並給出建議，而健康中心部分則讓用戶加入特定健康社群，進一步獲取專業的建議。',
                        '能遇到的問題與解決方法：由於健康指標和得分對某些用戶而言可能過於抽象，我們增加了每個指標的解釋以及行動建議，讓用戶更容易理解並根據建議進行自我改善。'
                    ],
                    en: [
                        'These pages include an overview of health index, activity scores, and the option to join the wellness hub. The health index page aggregates stress, activity, and sleep data, while the activity score page shows daily activity status with recommendations. The wellness hub allows users to join specific health communities for expert advice.',
                        'Potential issues and solutions: Health indicators and scores may be too abstract for some users, so we added explanations and action suggestions to make them easier to understand and apply for self-improvement.'
                    ]
                }
            }},
            { image: images.medical4, content: {
                heading: { zh: '設備配對與心律測量', en: 'Device Pairing & Heart Rate Measurement' },
                texts: {
                    zh: [
                        '用戶可能在設備配對過程中遇到困難，例如無法成功配對。為解決這一問題，我們加入了逐步的配對指引及故障排除提示，確保用戶能輕鬆完成設備連接。'
                    ],
                    en: [
                        'Users may encounter difficulties during device pairing, such as unsuccessful connections. To address this, we included step-by-step pairing instructions and troubleshooting tips to ensure seamless device connectivity.'
                    ]
                }
            }},
        ]
    },
    project5: {
        title: { zh: '航班票券管理APP', en: 'Flight Ticket Management App' },
        description: {
            zh: '一款飛行管理和社交互動 APP，幫助用戶查詢航班、管理行程，並與同機乘客互動，提升飛行體驗。',
            en: 'An app for flight management and social interaction, helping users check flights, manage itineraries, and interact with fellow passengers to enhance the flight experience.'
        },
        cardInfo: [
            { heading: { zh: '製作目的', en: 'Purpose' }, content: { zh: '簡化航班管理，並增加飛行過程中的社交互動', en: 'To simplify flight management and enhance social interactions during flights.' }},
            { heading: { zh: '使用工具', en: 'Tools Used' }, content: { zh: 'Figma/illustrator', en: 'Figma/Illustrator' }},
            { heading: { zh: '製作時間', en: 'Duration' }, content: { zh: '2週設計', en: '2 weeks design' }}
        ],
        imagesList: [
            { image: images.ticket1, content: { 
                heading: { zh: '主要問題與解決思路', en: 'Key Issues & Solutions' },
                texts: {
                    zh: [
                        '這款 APP 主要面臨如何讓用戶方便地管理航班信息和提升飛行過程中的互動性。',
                        '設計了簡潔的航班信息查詢和管理界面，並加入社交功能，讓用戶可以查看同機乘客並進行交流，提升用戶的參與感。'
                    ],
                    en: [
                        'The main challenge was how to allow users to easily manage flight information and enhance interactions during flights.',
                        'We designed a simple flight information query and management interface, adding social features that allow users to view and communicate with fellow passengers, increasing engagement.'
                    ]
                }
            }},
            { image: images.ticket2 },
            { image: images.ticket3 },
            { image: images.ticket4 }
        ]
    },
    project6: {
        title: { zh: '羽球雙打配對', en: 'Badminton Doubles Matching' },
        description: {
            zh: '一款體育運動管理 APP，提供球場預約、賽事組織和即時比賽追蹤功能，幫助用戶簡化預訂過程並提高比賽的互動性。',
            en: 'A sports management app offering court reservations, match organization, and real-time match tracking, simplifying the booking process and enhancing game interactivity.'
        },
        cardInfo: [
            { heading: { zh: '製作目的', en: 'Purpose' }, content: { zh: '提供簡單的預訂和即時管理工具，方便組織和參與比賽', en: 'To provide easy booking and management tools for organizing and participating in matches.' }},
            { heading: { zh: '使用工具', en: 'Tools Used' }, content: { zh: 'Figma/illustrator', en: 'Figma/Illustrator' }},
            { heading: { zh: '製作時間', en: 'Duration' }, content: { zh: '2週設計', en: '2 weeks design' }}
        ],
        imagesList: [
            { image: images.sport1, content: { 
                heading: { zh: '主要問題與解決思路', en: 'Key Issues & Solutions' },
                texts: {
                    zh: [
                        '主要挑戰是如何有效管理球場預訂和比賽活動，確保靈活性與互動性。',
                        '設計了集中管理的系統，整合預約、比賽安排、報名和即時賽況功能，減少繁瑣的溝通環節。'
                    ],
                    en: [
                        'The main challenge was managing court reservations and matches effectively while ensuring flexibility and interactivity.',
                        'We designed a centralized management system, integrating reservation, match scheduling, registration, and real-time updates, reducing communication overhead.'
                    ]
                }
            }},
            { image: images.sport2 },
            { image: images.sport3 },
            { image: images.sport4 }
        ]
    },
    project7: {
        title: { zh: '專為比賽設計的計時器', en: 'Competition Timer App' },
        description: {
            zh: '專為車輛愛好者設計的性能監控 APP，提供速度測試、加速測試、車輛校正和設備連接等功能，幫助用戶全面掌握車輛性能。',
            en: 'A performance monitoring app designed for vehicle enthusiasts, offering speed testing, acceleration testing, vehicle calibration, and device connectivity for comprehensive performance insights.'
        },
        cardInfo: [
            { heading: { zh: '製作目的', en: 'Purpose' }, content: { zh: '提升車輛駕駛體驗和操作性能', en: 'To enhance driving experience and vehicle performance.' }},
            { heading: { zh: '使用工具', en: 'Tools Used' }, content: { zh: 'Figma/illustrator', en: 'Figma/Illustrator' }},
            { heading: { zh: '製作時間', en: 'Duration' }, content: { zh: '2週設計', en: '2 weeks design' }}
        ],
        imagesList: [
            { image: images.motor1, content: { 
                heading: { zh: '主要問題與解決思路', en: 'Key Issues & Solutions' },
                texts: {
                    zh: [
                        '挑戰是如何有效管理車輛數據和測試，確保信息準確性和穩定連接。',
                        '設計了速度和加速測試儀表，提供藍牙設備連接及車輛校正功能，確保數據精確。'
                    ],
                    en: [
                        'The challenge was managing vehicle data and tests effectively, ensuring accuracy and stable connectivity.',
                        'We designed speed and acceleration testing gauges, with Bluetooth device connectivity and vehicle calibration features for data accuracy.'
                    ]
                }
            }},
            { image: images.motor2 },
            { image: images.motor3 },
            { image: images.motor4 },
            { image: images.motor5 }
        ]
    },
    project8: {
        title: { zh: '倉儲管理配單系統', en: 'Warehouse Management System' },
        description: {
            zh: '一款供製造業使用的管理 APP，幫助用戶管理生產任務、庫存狀態及外包項目，提供全面的計劃安排和追蹤功能。',
            en: 'A management app for manufacturing, assisting users with production tasks, inventory status, and outsourcing projects, offering comprehensive planning and tracking features.'
        },
        cardInfo: [
            { heading: { zh: '製作目的', en: 'Purpose' }, content: { zh: '簡化管理流程，提高生產和外包流程的透明度和效率', en: 'To simplify management processes, enhancing transparency and efficiency in production and outsourcing.' }},
            { heading: { zh: '使用工具', en: 'Tools Used' }, content: { zh: 'Figma/illustrator', en: 'Figma/Illustrator' }},
            { heading: { zh: '製作時間', en: 'Duration' }, content: { zh: '2週設計', en: '2 weeks design' }}
        ],
        imagesList: [
            { image: images.good1, content: { 
                heading: { zh: '主要問題與解決思路', en: 'Key Issues & Solutions' },
                texts: {
                    zh: [
                        '主要挑戰是有效管理生產、庫存和外包流程，確保及時和準確的信息流通。',
                        '設計了集成管理系統，包含日曆、任務管理、庫存追蹤和外包配置功能。'
                    ],
                    en: [
                        'The main challenge was effectively managing production, inventory, and outsourcing processes, ensuring timely and accurate information flow.',
                        'We designed an integrated management system with calendar, task management, inventory tracking, and outsourcing configuration functions.'
                    ]
                }
            }},
            { image: images.good2 },
            { image: images.good3 },
            { image: images.good4 }
        ]
    },
    project9: {
        title: { zh: '虛擬貨幣交易所', en: 'Cryptocurrency Exchange' },
        description: {
            zh: '提供從購買到廣告管理的一站式服務，幫助用戶進行透明、安全的數位貨幣交易。',
            en: 'A one-stop service from purchasing to ad management, helping users conduct transparent and secure cryptocurrency transactions.'
        },
        cardInfo: [
            { heading: { 
                zh: '製作目的', 
                en: 'Purpose' }, 
                content: { 
                    zh: '輕鬆掌握市場動態並管理交易', 
                    en: 'To easily track market trends and manage trades.' }},
            { heading: { 
                zh: '使用工具', 
                en: 'Tools Used' }, 
                content: { 
                    zh: 'Figma/illustrator', 
                    en: 'Figma/Illustrator' }},
            { heading: { 
                zh: '製作時間', 
                en: 'Duration' }, 
                content: { 
                    zh: '2週設計', 
                    en: '2 weeks design' }}
        ],
        imagesList: [
            { image: images.trade1, content: { 
                heading: { 
                    zh: '主要問題與解決思路', 
                    en: 'Key Issues & Solutions' },
                texts: {
                    zh: [
                        '主要挑戰是幫助用戶清晰掌握市場動態並便於管理買賣廣告。',
                        '提供詳細的交易圖表和廣告管理功能，幫助用戶實時掌握價格走勢。'
                    ],
                    en: [
                        'The main challenge was helping users clearly understand market trends and easily manage trade ads.',
                        'We provided detailed trading charts and ad management functions to help users monitor price trends in real time.'
                    ]
                }
            }},
            { image: images.trade2 },
            { image: images.trade3 },
            { image: images.trade4 }
        ]
    },
};

export default CardData;