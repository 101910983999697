import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import Web1 from './project-pages/website-trondex';
import Web2 from './project-pages/website-company';
import Web3 from './project-pages/website-nft';
import Web4 from './project-pages/appui-medical';
import Web5 from './project-pages/appui-ticket';
import Web6 from './project-pages/appui-tennis-matching';
import Web7 from './project-pages/project-timer';
import Web8 from './project-pages/appui-management';
import Web9 from './project-pages/project-crypto-trading';

function Index() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/project-pages/website-trondex" element={<Web1 />} />
                <Route path="/project-pages/website-company" element={<Web2 />} />
                <Route path="/project-pages/website-nft" element={<Web3 />} />
                <Route path="/project-pages/appui-medical" element={<Web4 />} />
                <Route path="/project-pages/appui-ticket" element={<Web5 />} />
                <Route path="/project-pages/appui-tennis-matching" element={<Web6 />} />
                <Route path="/project-pages/project-timer" element={<Web7 />} />
                <Route path="/project-pages/appui-management" element={<Web8 />} />
                <Route path="/project-pages/project-crypto-trading" element={<Web9 />} />
            </Routes>
        </Router>
    );
}

export default Index;
