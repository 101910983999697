// assets/images.js
import logo from './logo.svg';
import a1 from './ic_a1.svg';
import a2 from './ic_a2.svg';
import a3 from './ic_a3.svg';
import web1 from './web_1.png';
import web2 from './web_2.png';
import web3 from './web_3.png';
import phone1 from './phone_1.png';
import phone2 from './phone_2.png';
import phone3 from './phone_3.png';
import phone4 from './phone_4.png';
import phone5 from './phone_5.png';
import phone6 from './phone_6.png';
import trondex1 from './web_trondex_1.png';
import trondex2 from './web_trondex_2.png';
import trondex3 from './web_trondex_3.png';
import trondex4 from './web_trondex_4.png';
import fs1 from './fs_1.png';
import fs2 from './fs_2.png';
import fs3 from './fs_3.png';
import eternal1 from './web_nft_1.png';
import eternal2 from './web_nft_2.png';
import eternal3 from './web_nft_3.png';
import medical1 from './medical_1.png';
import medical2 from './medical_2.png';
import medical3 from './medical_3.png';
import medical4 from './medical_4.png';
import ticket1 from './ticket_1.png';
import ticket2 from './ticket_2.png';
import ticket3 from './ticket_3.png';
import ticket4 from './ticket_4.png';
import sport1 from './sport_1.png';
import sport2 from './sport_2.png';
import sport3 from './sport_3.png';
import sport4 from './sport_4.png';
import good1 from './good_1.png';
import good2 from './good_2.png';
import good3 from './good_3.png';
import good4 from './good_4.png';
import motor1 from './motor_1.png';
import motor2 from './motor_2.png';
import motor3 from './motor_3.png';
import motor4 from './motor_4.png';
import motor5 from './motor_5.png';
import trade1 from './trade_1.png';
import trade2 from './trade_2.png';
import trade3 from './trade_3.png';
import trade4 from './trade_4.png';

const images = {
    logo,
    a1,
    a2,
    a3,
    web1,
    web2,
    web3,
    phone1,
    phone2,
    phone3,
    phone4,
    phone5,
    phone6,
    trondex1,
    trondex2,
    trondex3,
    trondex4,
    fs1,
    fs2,
    fs3,
    eternal1,
    eternal2,
    eternal3,
    medical1,
    medical2,
    medical3,
    medical4,
    ticket1,
    ticket2,
    ticket3,
    ticket4,
    sport1,
    sport2,
    sport3,
    sport4,
    good1,
    good2,
    good3,
    good4,
    motor1,
    motor2,
    motor3,
    motor4,
    motor5,
    trade1,
    trade2,
    trade3,
    trade4,

};

export default images;
