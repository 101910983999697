import React from 'react';

function Footer({ className }) {
    return (
        <footer className={`footer ${className}`}>
            <p>Thank you for being here.</p>
            <section className='footer-container'><p>© 2024 Tedd Tang All Rights Reserved</p>
            <p className='line'>|</p>
            <p>Email : cd410080@gmail.com</p></section>
            
        </footer>
    );
}

export default Footer;
