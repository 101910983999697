import React, { useState, useEffect, useRef } from 'react';
import { Link, Element } from 'react-scroll';
import { useNavigate } from 'react-router-dom';
import './styles/styles.scss';
import ProjectCard from './pages/ProjectCard';
import projects from './pages/Data'; 
import images from './assets/images';
import Footer from './pages/Footer';
import { motion } from 'framer-motion';
import Header from './pages/Header'
import translations from './pages/translations';

function Home() {
    const navigate = useNavigate();
    const [showContent, setShowContent] = useState(false);
    const backgroundRef = useRef(null); 
    const [animatingCardId, setAnimatingCardId] = useState(null);
    const [isAnimating, setIsAnimating] = useState(false);
    const [targetLink, setTargetLink] = useState('');
    const [circlePosition, setCirclePosition] = useState({ x: 0, y: 0 });
    const [expansionDirection, setExpansionDirection] = useState('center');

    // 從 localStorage 中獲取語言偏好，默認為瀏覽器語言
    const getDefaultLanguage = () => {
        const storedLanguage = localStorage.getItem('language');
        return storedLanguage || (navigator.language.startsWith('zh') ? 'zh' : 'en');
    };

    const [language, setLanguage] = useState(getDefaultLanguage());
    
    // 語言切換函數，並存儲到 localStorage
    const toggleLanguage = () => {
        const newLanguage = language === 'zh' ? 'en' : 'zh';
        setLanguage(newLanguage);
        localStorage.setItem('language', newLanguage); // 儲存到 localStorage
    };

    useEffect(() => {
        // 設置 body 的 class 名稱來改變樣式
        document.body.classList.remove('zh-style', 'en-style');
        document.body.classList.add(language === 'zh' ? 'zh-style' : 'en-style');
    }, [language]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowContent(true);
        }, 2100);
        return () => clearTimeout(timer);
    }, []);

    const handleCardClick = (id, link, event, index) => {
        if (isAnimating) return;
    
        // 获取点击卡片的位置信息，确保是整个卡片的中心
        const rect = event.currentTarget.getBoundingClientRect();
        const circleX = rect.left + rect.width / 3;
        const circleY = rect.top + rect.height / 3;
        setCirclePosition({ x: circleX, y: circleY });
    
        // 判断一行中卡片的数量
        const isLargeScreen = window.innerWidth > 768;
        const cardsPerRow = isLargeScreen ? 3 : 1;
    
        // 判断卡片在一行中的位置
        const positionInRow = index % cardsPerRow;
    
        if (positionInRow === 0) {
            // 卡片在左侧
            setExpansionDirection('left');
        } else if (positionInRow === cardsPerRow - 1) {
            // 卡片在右侧
            setExpansionDirection('right');
        } else {
            // 卡片在中间
            setExpansionDirection('center');
        }
    
        // 设置动画状态
        setAnimatingCardId(id);
        setIsAnimating(true);
        setTargetLink(link);
    
        // 延迟跳转，设置一个自定义的 delay 时间
        setTimeout(() => {
            navigate(link);
        }, 400); // 0.8秒后跳转
    };
    

    return (
        <div className='page-container'>
            <div ref={backgroundRef} className="background-slide"></div>
            <div className='initial-text'>Tedd Tang</div>
            <div className="content">
            <Header language={language} toggleLanguage={toggleLanguage} />
                <div name='services' className="spotlight-container">
                    <div className='banner'>
                        <div className='wrapper-title'>
                            <h1 className={`title ${showContent ? "animate" : ""}`}>
                                {translations.title[language]}
                            </h1>
                        </div>
                        <div className='wrapper-text'>
                            <h2 className={`text ${showContent ? "animate" : ""}`}>{translations.description[language]}</h2>
                        </div>
                        <div className='wrapper-hint'>
                            <Link to="portfolio" smooth={true} duration={800} easing="easeInOutCubic" className={`hint-link ${showContent ? "animate" : ""}`}>
                                <h5 className='hint'>
                                    {translations.hint[language]}
                                </h5>
                            </Link>
                        </div>
                    </div>
                    
                        <div className={`ad-column1 ${showContent ? "animate" : ""}`}>
                            <section className='ad'>
                                <span className='ad-topic'>
                                    <img src={images.a2} alt="" />
                                    <h4>{translations.ad1Title[language]}</h4>
                                </span>
                                <h5>
                                {translations.ad1Description[language]}
                                </h5>
                            </section>
                            <section className='ad'>
                                <span className='ad-topic'>
                                    <img src={images.a1} alt="" />
                                    <h4>{translations.ad2Title[language]}</h4>
                                </span>
                                <h5>
                                {translations.ad2Description[language]}
                                </h5>
                            </section>
                            <section className='ad'>
                                <span className='ad-topic'>
                                    <img src={images.a3} alt="" />
                                    <h4>{translations.ad3Title[language]}</h4>
                                </span>
                                <h5>
                                {translations.ad3Description[language]}
                                </h5>
                            </section>
                        </div>
                    
                    <Element name='portfolio' className={`portfolio ${showContent ? "animate" : ""}`}>
                        <h5>{translations.subTitle[language]}</h5>
                        <div className='project-container'>
                        {projects.map((project, index) => (
                                <ProjectCard
                                    key={index}
                                    id={index}
                                    image={project.image}
                                    title={project.title[language]} // 根據當前語言傳遞 title
                                    description={project.description[language]} // 根據當前語言傳遞 description
                                    link={project.link}
                                    onClick={(id, link, e) => handleCardClick(id, link, e, index)}
                                />
                            ))}
                        </div>
                    </Element>
                </div>
                
            </div>
            <Footer className={`footer ${showContent ? "animate" : ""}`}/>
            {isAnimating && (
                <motion.div
                className="circle-overlay"
                style={{
                    top: circlePosition.y,
                    left: circlePosition.x,
                    transform: expansionDirection === 'left' ? 'translate(-50%, -50%)' : 'translate(50%, -50%)',
                }}
                initial={{
                    scale: 0,
                    borderRadius: '50%',
                    width: 100,
                    height: 100,
                }}
                animate={{
                    scale: [1, 10, 20],
                    borderRadius: ['50%', '20%', '0%'],
                    width: ['100px', '800px', '100vw'],
                    height: ['100px', '800px', '100vh'],
                    x: expansionDirection === 'left' ? 0 : '-100vw',
                }}
                transition={{ duration: 1.5, ease: "easeInOut" }}
            />
            
            )}
        </div>
    );
}

export default Home;
