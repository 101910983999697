// import React, { useState, useEffect, useRef } from 'react';
// import '../styles/styles.scss';
// import images from '../assets/images';
// import Footer from '../pages/Footer';
// import Header from '../pages/Header'

// function Web2() {
//     const backgroundRef = useRef(null); //
//     const [showContent, setShowContent] = useState(false);
//     useEffect(() => {
//         const timer = setTimeout(() => {
//             setShowContent(true);
//         }, 2100);
//         return () => clearTimeout(timer);
//     }, []);

//     const [showInitialText, setShowInitialText] = useState(false);

//     useEffect(() => {
//         const timer = setTimeout(() => {
//             setShowInitialText(true);
//         }, 500); 

//         return () => clearTimeout(timer); 
//     }, []);

//     return (
//         <div className='page-container'>
//             <div ref={backgroundRef} className="background-slide"></div>
//             {showInitialText && (
//                 <div className='initial-text fade-in'>Tedd Tang</div>
//             )}
            
//             <div className={`content ${showContent ? "show" : ""}`}>
//                 <Header />
//                 <div className='project-container_info'>
//                     <div className={`card-wrapper ${showContent ? "animate" : ""}`}>
//                         <section className='card'>
//                             <h5 className=''>作品名稱</h5>
//                             <p className='card-title'>科技公司形象網站</p>
//                             <p className='card-content'>形象網站的設計旨在呈現公司在創新與技術領域的領先地位。整體設計採用現代而簡潔的風格，搭配冷色系的配色，如深藍、灰色和白色，以傳達科技的專業感與未來感。</p>
//                         </section>
//                         <div className='card-group'>
//                             <section className='card'>
//                                 <h5>製作目的</h5>
//                                 <p className='card-type'>提升公司在科技領域的品牌形象，展現創新實力與技術專業性</p>
//                             </section>
//                             <section className='card'>
//                                 <h5>使用工具</h5>
//                                 <p className='card-type'>Figma/Html/Scss/React.js</p>
//                             </section>
//                             <section className='card'>
//                                 <h5>製作時間</h5>
//                                 <p className='card-type'>1週設計, 1週開發</p>
//                             </section>
//                         </div>
//                     </div>
//                     <div className={`card-img ${showContent ? "animate" : ""}`} >
//                         <img src={images.fs1} alt="" />
//                         <section className='card'>
//                             <h5>主要問題與解決思路</h5>
//                             <p className='card-content_more'>
//                             在設計 UI 的過程中，我們主要面臨兩個挑戰：其一是如何確保設計風格在不同平台和設備上保持一致且具備吸引力，其二是如何創造出能夠提升用戶體驗的互動設計。
//                             </p>
//                             <p className='card-content_more'>為了解決這些問題，我們專注於現代 UI/UX 設計，結合響應式設計技術，確保網站在各種設備上都能提供流暢和一致的視覺體驗。我們還注重使用微互動（micro-interactions）來增強用戶參與度，使整體設計更具親和力和未來感。</p>
//                         </section>
//                         <img src={images.fs2} alt="" />
//                         <img src={images.fs3} alt="" />
//                     </div>
                    
//                 </div>
                
//             </div>
//             <Footer className={`footer ${showContent ? "animate" : ""}`}/>
            
//         </div>
//     );
// }

// export default Web2;

import React from 'react';
import ContentPage from '../pages/ContentPage';
import cardData from '../pages/CardData';
import projects from '../pages/Data';

function company() {
    const projectIndex = 1; 
    const totalProjects = Object.keys(projects).length;
    const { title, description, cardInfo, imagesList } = cardData.project2;

    return (
        <ContentPage
            title={title}
            description={description}
            cardInfo={cardInfo}
            imagesList={imagesList}
            projectIndex={projectIndex}
            totalProjects={totalProjects}
        />
    );
}

export default company;

