import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Index from './index.jsx'; // 引入包含路由的 Index 組件

ReactDOM.render(
  <React.StrictMode>
    <Index /> {/* 渲染包含路由的 Index 組件 */}
  </React.StrictMode>,
  document.getElementById('root')
);
