import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { scroller } from 'react-scroll';
import images from '../assets/images';
import '../styles/styles.scss';
import translations from './translations';

function Header({ language, toggleLanguage, className }) {
    const navigate = useNavigate();
    const [showHeader, setShowHeader] = useState(true);  // 控制 Header 的顯示狀態
    const [lastScrollY, setLastScrollY] = useState(0);    // 記錄最後的滾動位置

    const handlePortfolioClick = () => {
        navigate('/');
        setTimeout(() => {
            scroller.scrollTo('portfolio', {
                duration: 500,
                delay: 100,
                smooth: 'easeInOutCubic',
            });
        }, 100);
    };

    const displayLanguage = language === 'zh' ? '繁中' : 'EN';

    // 控制 Header 顯示動畫的狀態
    const [showContent, setShowContent] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowContent(true);
        }, 2500);
        return () => clearTimeout(timer);
    }, []);

    // 追蹤滾動位置並更新 Header 顯示狀態
    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;

            if (currentScrollY > lastScrollY && currentScrollY > 100) {
                setShowHeader(false); // 向下滾動且滾動距離超過 100 時隱藏 Header
            } else {
                setShowHeader(true);  // 向上滾動時顯示 Header
            }
            setLastScrollY(currentScrollY); // 更新最後的滾動位置
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [lastScrollY]);

    return (
        <nav className={`header ${showContent ? "animate" : ""} ${showHeader ? "" : "hidden"}`}>
            <div className='nav-logo' onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>
                <img src={images.logo} alt="" />
            </div>
            <div className='link-container'>
                <button onClick={handlePortfolioClick} className='header-button'>
                    {translations.projectHeader[language]}
                </button>
                <button onClick={toggleLanguage} className='header-button'>
                    {displayLanguage}
                </button>
            </div>
        </nav>
    );
}

export default Header;
