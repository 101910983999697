import React from 'react';
import ContentPage from '../pages/ContentPage';
import cardData from '../pages/CardData';
import projects from '../pages/Data';

function trondex() {
    const projectIndex = 0; // trondex 对应的项目索引，0 代表第一个项目
    const totalProjects = Object.keys(projects).length; // 获取项目总数
    const { title, description, cardInfo, imagesList } = cardData.project1;

    return (
        <ContentPage
            title={title}
            description={description}
            cardInfo={cardInfo}
            imagesList={imagesList}
            projectIndex={projectIndex}
            totalProjects={totalProjects}
        />
    );
}

export default trondex;

