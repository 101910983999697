import React, { useState, useEffect, useRef } from 'react';
import { Link, Element } from 'react-scroll';
import { useNavigate } from 'react-router-dom';
import '../styles/styles.scss';
import Footer from '../pages/Footer';
import Header from '../pages/Header';
import projects from '../pages/Data';
import ProjectCard from '../pages/ProjectCard';
import { motion } from 'framer-motion';
import translations from '../pages/translations';

function ContentPage({ title, description, cardInfo, imagesList, projectIndex, totalProjects }) {
    const backgroundRef = useRef(null);
    const [showContent, setShowContent] = useState(false);
    const [showInitialText, setShowInitialText] = useState(false);

    const navigate = useNavigate();
    const [animatingCardId, setAnimatingCardId] = useState(null);
    const [isAnimating, setIsAnimating] = useState(false);
    const [targetLink, setTargetLink] = useState('');
    const [circlePosition, setCirclePosition] = useState({ x: 0, y: 0 });
    const [expansionDirection, setExpansionDirection] = useState('center');

    // 從 localStorage 中獲取語言偏好，默認為瀏覽器語言
    const getDefaultLanguage = () => {
        const storedLanguage = localStorage.getItem('language');
        return storedLanguage || (navigator.language.startsWith('zh') ? 'zh' : 'en');
    };

    const [language, setLanguage] = useState(getDefaultLanguage());

    // 語言切換函數
    const toggleLanguage = () => {
        const newLanguage = language === 'zh' ? 'en' : 'zh';
        setLanguage(newLanguage);
        localStorage.setItem('language', newLanguage); // 儲存到 localStorage
    };

    useEffect(() => {
        document.body.classList.remove('zh-style', 'en-style');
        document.body.classList.add(language === 'zh' ? 'zh-style' : 'en-style');
    }, [language]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowContent(true);
        }, 2100);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowInitialText(true);
        }, 100);
        return () => clearTimeout(timer);
    }, []);

    const getNextThreeProjects = () => {
        let startIndex = projectIndex + 1;
        let endIndex = startIndex + 3;
    
        if (endIndex > projects.length) {
            const part1 = projects.slice(startIndex);
            const part2 = projects.slice(0, endIndex - projects.length);
            return part1.concat(part2);
        } else {
            return projects.slice(startIndex, endIndex);
        }
    };
    
    
    const handleCardClick = (id, link, event, index) => {
        if (isAnimating) return;
    
        // 获取点击卡片的位置信息，确保是整个卡片的中心
        const rect = event.currentTarget.getBoundingClientRect();
        const circleX = rect.left + rect.width / 3;
        const circleY = rect.top + rect.height / 3;
        setCirclePosition({ x: circleX, y: circleY });
    
        // 判断一行中卡片的数量
        const isLargeScreen = window.innerWidth > 768;
        const cardsPerRow = isLargeScreen ? 3 : 1;
    
        // 判断卡片在一行中的位置
        const positionInRow = index % cardsPerRow;
    
        if (positionInRow === 0) {
            // 卡片在左侧
            setExpansionDirection('left');
        } else if (positionInRow === cardsPerRow - 1) {
            // 卡片在右侧
            setExpansionDirection('right');
        } else {
            // 卡片在中间
            setExpansionDirection('center');
        }
    
        // 设置动画状态
        setAnimatingCardId(id);
        setIsAnimating(true);
        setTargetLink(link);
    
        // 延迟跳转，设置一个自定义的 delay 时间
        setTimeout(() => {
            navigate(link);
        }, 400); // 0.8秒后跳转
    };

    return (
        <div className='page-container'>
            <div ref={backgroundRef} className="background-slide"></div>
            {showInitialText && (
                <div className='initial-text fade-in'>Tedd Tang</div>
            )}
            
            <div className={`content ${showContent ? "show" : ""}`}>
            <Header language={language} toggleLanguage={toggleLanguage} />
                <div className='project-container_info'>
                    <div className={`card-wrapper ${showContent ? "animate" : ""}`}>
                        <section className='card'>
                            <h5>{translations.sub3Title[language]}</h5>
                            <p className='card-title'>{title[language]}</p>
                            <p className='card-content'>{description[language]}</p>
                        </section>
                        <div className='card-group'>
                            {cardInfo.map((info, index) => (
                                <section key={index} className='card'>
                                    <h5>{info.heading[language]}</h5>
                                    <p className='card-type'>{info.content[language]}</p>
                                </section>
                            ))}
                        </div>
                    </div>
                    <div className={`card-img ${showContent ? "animate" : ""}`} >
                        {imagesList.map((item, index) => (
                            <React.Fragment key={index}>
                                {item.image && <img src={item.image} alt="" />}
                                {item.content && (
                        <section className='card'>
                            <h5>{item.content.heading[language]}</h5>
                            {Array.isArray(item.content.texts) ? (
                                item.content.texts.map((text, idx) => (
                                    <p key={idx} className='card-content_more'>{text[language]}</p>
                                ))
                            ) : (
                                <p className='card-content_more'>{item.content.texts[language]}</p>
                            )}
    </section>
)}

                            </React.Fragment>
                        ))}
                    </div>
                    <div className='next-projects'>
                        <Element name='portfolio' className={`portfolio ${showContent ? "animate" : ""}`}>
                            <h5>{translations.sub2Title[language]}</h5>
                            <div className='project-container'>
                                {getNextThreeProjects().map((project, index) => (
                                    <ProjectCard
                                        key={index}
                                        id={index}
                                        image={project.image}
                                        title={project.title[language]} // 根據當前語言傳遞 title
                                    description={project.description[language]} // 根據當前語言傳遞 description
                                    link={project.link}
                                        onClick={(id, link, e) => handleCardClick(id, link, e, index)}
                                    />
                                ))}
                            </div>
                        </Element>
                    </div>
                </div>
                

            </div>
            <Footer className={`footer ${showContent ? "animate" : ""}`} />
            {isAnimating && (
                <motion.div
                className="circle-overlay"
                style={{
                    top: circlePosition.y,
                    left: circlePosition.x,
                    transform: expansionDirection === 'left' ? 'translate(-50%, -50%)' : 'translate(50%, -50%)',
                }}
                initial={{
                    scale: 0,
                    borderRadius: '50%',
                    width: 100,
                    height: 100,
                }}
                animate={{
                    scale: [1, 10, 20],
                    borderRadius: ['50%', '20%', '0%'],
                    width: ['100px', '800px', '100vw'],
                    height: ['100px', '800px', '100vh'],
                    x: expansionDirection === 'left' ? 0 : '-100vw',
                }}
                transition={{ duration: 1.5, ease: "easeInOut" }}
            />
            
            )}
        </div>
    );
}

export default ContentPage;