const translations = {
    title: {
        zh: "簡約而不簡單的設計力量",
        en: "The Power of Simple Profound Design",
    },
    description: {
        zh: "我是 Tedd，一名擁有8年以上經驗的UI設計師，致力於創造簡單而富有創新性的用戶界面和體驗。我專注於以穩定的心態面對每個設計挑戰，並結合快速迭代的設計流程，確保產品在使用者需求和商業目標之間找到最佳平衡點",
        en: "I'm Tedd, a UI designer with over 8 years of experience, focused on creating simple and innovative user interfaces. I approach each challenge with a steady mindset and an iterative design process to balance user needs with business goals.",
    },
    hint: {
        zh: "同時也能是一名土法煉鋼型的前端設計師，往下繼續 ↓",
        en: "I can also be a hands-on front-end designer, scroll down ↓",
    },
    ad1Title: {
        zh: "功能規劃與佈局",
        en: "Feature Planning",
    },
    ad1Description: {
        zh: "我們會深入了解您的業務需求和市場定位，提供功能上的建議，不僅提升用戶操作體驗，還能增強品牌質感",
        en: "We understand your business needs and provide functional insights to enhance user experience and brand quality.",
    },
    ad2Title: {
        zh: "響應式介面設計",
        en: "Responsive Design",
    },
    ad2Description: {
        zh: "提供自訂化的佈局設計和卓越的使用者體驗，確保無論在何種設備上，皆能呈現最佳的視覺效果和操作流暢度",
        en: "Provides customized layout design and an excellent user experience, ensuring optimal visual effects and smooth operation across all devices.",
    },
    ad3Title: {
        zh: "用戶研究與分析",
        en: "UX and Analysis",
    },
    ad3Description: {
        zh: "搜尋並分析同類型的應用程式，深入了解其優缺點，將這些洞察應用到設計端，以優化我們的使用者體驗設計",
        en: "Research similar apps to understand their strengths and weaknesses, applying these insights to optimize user experience.",
    },
    subTitle: {
        zh: "作品展示區",
        en: "Work Gallery",
    },
    sub2Title: {
        zh: "更多作品",
        en: "More Work Gallery",
    },
    sub3Title: {
        zh: "作品名稱",
        en: "Project",
    },
    projectHeader: {
        zh: "作品展示",
        en: "Projects",
    },
};

export default translations;
