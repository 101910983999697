import React from 'react';

function ProjectCard({ id, image, title, description, link, onClick }) {
    const handleClick = (e) => {
        e.preventDefault();
        onClick(id, link, e); // 使用事件對象 e 來獲取卡片的位置信息
    };

    return (
        <div className="project-effect" onClick={handleClick}>
            <div className="project">
                <img src={image} alt={title} />
                <div className="project-info">
                    <span>{title}</span>
                    <h5>{description}</h5>
                </div>
            </div>
        </div>
    );
}

export default ProjectCard;
