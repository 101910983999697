import images from '../assets/images'; // 根據實際圖片文件的位置來調整導入路徑

const projects = [
    {
        image: images.web1,
        title: {
            zh: '網頁設計',
            en: 'Web Design'
        },
        description: {
            zh: '踏入未來的市場掌控',
            en: 'Stepping into the future of market control'
        },
        link: '/project-pages/website-trondex',
    },
    {
        image: images.web2,
        title: {
            zh: '網頁設計',
            en: 'Web Design'
        },
        description: {
            zh: '公司形象網頁',
            en: 'Corporate Branding Website'
        },
        link: '/project-pages/website-company',
    },
    {
        image: images.web3,
        title: {
            zh: '網頁設計',
            en: 'Web Design'
        },
        description: {
            zh: 'NFT 網頁',
            en: 'NFT Website'
        },
        link: '/project-pages/website-nft',
    },
    {
        image: images.phone1,
        title: {
            zh: 'APP UI 設計',
            en: 'App UI Design'
        },
        description: {
            zh: '運動醫療健康監測',
            en: 'Sports Health Monitoring'
        },
        link: '/project-pages/appui-medical',
    },
    {
        image: images.phone2,
        title: {
            zh: 'APP UI 設計',
            en: 'App UI Design'
        },
        description: {
            zh: '航班票券管理',
            en: 'Flight Ticket Management'
        },
        link: '/project-pages/appui-ticket',
    },
    {
        image: images.phone3,
        title: {
            zh: 'APP UI 設計',
            en: 'App UI Design'
        },
        description: {
            zh: '羽球雙打配對',
            en: 'Badminton Doubles Matching'
        },
        link: '/project-pages/appui-tennis-matching',
    },
    {
        image: images.phone4,
        title: {
            zh: 'APP UI 設計',
            en: 'App UI Design'
        },
        description: {
            zh: '專為比賽設計的計時器',
            en: 'Competition Timer Design'
        },
        link: '/project-pages/project-timer',
    },
    {
        image: images.phone5,
        title: {
            zh: 'APP UI 設計',
            en: 'App UI Design'
        },
        description: {
            zh: '倉儲管理配單系統',
            en: 'Warehouse Management System'
        },
        link: '/project-pages/appui-management',
    },
    {
        image: images.phone6,
        title: {
            zh: 'APP UI 設計',
            en: 'App UI Design'
        },
        description: {
            zh: '虛擬貨幣交易所',
            en: 'Cryptocurrency Exchange Platform'
        },
        link: '/project-pages/project-crypto-trading',
    },
];

export default projects;
