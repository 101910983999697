import React from 'react';
import ContentPage from '../pages/ContentPage';
import cardData from '../pages/CardData';
import projects from '../pages/Data';

function timer() {
    const projectIndex = 8; 
    const totalProjects = Object.keys(projects).length;
    const { title, description, cardInfo, imagesList } = cardData.project9;

    return (
        <ContentPage
            title={title}
            description={description}
            cardInfo={cardInfo}
            imagesList={imagesList}
            projectIndex={projectIndex}
            totalProjects={totalProjects}
        />
    );
}

export default timer;

